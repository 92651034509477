import { render, staticRenderFns } from "./BulkInfrastructureUpgradeStarTable.vue?vue&type=template&id=08dc7894&scoped=true"
import script from "./BulkInfrastructureUpgradeStarTable.vue?vue&type=script&lang=js"
export * from "./BulkInfrastructureUpgradeStarTable.vue?vue&type=script&lang=js"
import style0 from "./BulkInfrastructureUpgradeStarTable.vue?vue&type=style&index=0&id=08dc7894&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08dc7894",
  null
  
)

export default component.exports