import { render, staticRenderFns } from "./EmpiresTable.vue?vue&type=template&id=ac3fde86&scoped=true"
import script from "./EmpiresTable.vue?vue&type=script&lang=js"
export * from "./EmpiresTable.vue?vue&type=script&lang=js"
import style0 from "./EmpiresTable.vue?vue&type=style&index=0&id=ac3fde86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac3fde86",
  null
  
)

export default component.exports